import clsx from 'clsx'
import {memo, useMemo} from 'react'
// import {useDispatch} from 'react-redux'
// import {selectNavigation} from 'app/store/fuse/navigationSlice'
import TGSNavigation from './TGSNavigation'
import { useSelector } from 'react-redux'
// import {useThemeMediaQuery} from 'src/app/modules/hooks'


// import {navbarCloseMobile} from 'app/store/fuse/navbarSlice'

function Navigation(props) {
  const user_type = useSelector(({user}) =>user.user.type );
  // const navigation = useSelector(selectNavigation)
  // const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'))

  // const dispatch = useDispatch()

  return useMemo(() => {
    function handleItemClick(item) {
      // if (isMobile) {
      //   dispatch(navbarCloseMobile())
      // }
    }

    const navigationConfig = user_type === "sponsor" ? [
      {
        id: 'dashboards',
        title: 'Dashboards',
        subtitle: 'Unique dashboard designs',
        type: 'item',
        icon: 'heroicons-outline:home',
        translate: 'DASHBOARDS',
        url: '/dashboard',
      },
      {
        id: 'peers',
        title: 'Your Peers',
        subtitle: 'Custom made application designs',
        type: 'group',
        icon: 'heroicons-outline:phone-outgoing',
        translate: 'ACCOUNT',
        children: [
          {
            id: 'peers.invite',
            title: 'Invite your Peers',
            type: 'item',
            icon: 'heroicons-outline:mail',
            url: '/peers/invite',
            translate: 'PEERS',
          },
          // {
          //   id: 'accounts.billing',
          //   title: 'Billing',
          //   subtitle: '3 upcoming events',
          //   type: 'item',
          //   icon: 'heroicons-outline:cash',
          //   url: '/account/billing',
          //   translate: 'BILLING',
          //   role: 'user',
          // },
        ],
      },
      
      {
        id: 'youtube',
        title: 'Training',
        subtitle: 'Custom made page designs',
        type: 'item',
        icon: 'heroicons-outline:chart-pie',
        url: '/youtube',
      },
    ]:[
      {
        id: 'dashboards',
        title: 'Dashboards',
        subtitle: 'Unique dashboard designs',
        type: 'item',
        icon: 'heroicons-outline:home',
        translate: 'DASHBOARDS',
        url: '/dashboard',
      },
      {
        id: 'accounts',
        title: 'Account',
        subtitle: 'Custom made application designs',
        type: 'group',
        icon: 'heroicons-outline:user-circle',
        translate: 'ACCOUNT',
        children: [
          {
            id: 'accounts.info',
            title: 'Account Information',
            type: 'item',
            icon: 'heroicons-outline:academic-cap',
            url: '/account/info',
            translate: 'ACADEMY',
          },
          {
            id: 'accounts.billing',
            title: 'Billing',
            subtitle: '3 upcoming events',
            type: 'item',
            icon: 'heroicons-outline:cash',
            url: '/account/billing',
            translate: 'BILLING',
            role: 'user',
          },
        ],
      },
      {
        id: 'youtube',
        title: 'Training',
        subtitle: 'Custom made page designs',
        type: 'item',
        icon: 'heroicons-outline:chart-pie',
        url: '/youtube',
      },
    ]


    return (
      <TGSNavigation
        className={clsx('navigation', props.className)}
        navigation={navigationConfig}
        layout={props.layout}
        dense={props.dense}
        active={props.active}
        onItemClick={handleItemClick}
      />
    )
  }, [props.active, props.className, props.dense, props.layout])
}

Navigation.defaultProps = {
  layout: 'horizontal',
}

export default memo(Navigation)
