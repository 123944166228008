import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  NativeSelect,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
} from '@mui/material'
import axios from 'axios'
import React, {useEffect} from 'react'
import {API_URL} from 'src/app/modules/auth/core/_requests'
import {useAppDispatch, useAppSelector} from 'src/app/store/hook'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import SendIcon from '@mui/icons-material/Send';
import { showMessage } from 'src/app/store/fuse/messageSlice'

type Props = {}

const PeersInvite = (props: Props) => {
  const sponsor_id = useAppSelector(({user}) => user.user.dbkey_sponsor_master)
  const [domain, setDomain] = React.useState('')
  const [domains, setDomains] = React.useState<string[]>([])
  const [loading,setLoading] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [emailName,setEmailName] = React.useState<string>("");
  const [error,setError] = React.useState<string>("");
  const [pending,setPending] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (item: string) => {
    setDomain(item)
    setAnchorEl(null)
  }

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/sponsor/get`, {params: {sponsor_id}})
      .then((res) => {
        setDomains(res.data)
        setDomain(res.data[0])
      })
      .catch((err) => console.log(err)).finally(() => setLoading(false))
  }, [sponsor_id])

  const handleSubmit = () => {
    if(emailName.length > 0) {
        setError("")
        setPending(true);
        axios.post(`${API_URL}/sponsor/invite`,{email:emailName+ '@' + domain}).then(res => {
            dispatch(showMessage({message:res.data.message,variant:'success'}));
        }).catch(err => {console.log(err)}).finally(() => setPending(false))
    }else {
        setError("Please enter an email address.")
    }
  }

  return (
    <div>
      <Grid container>
        <TextField
          label='email'
          variant='outlined'
          value={emailName}
          onChange={(e) => {setError(e.target.value.length > 0 ?"":"Please enter an email address."); setEmailName(e.target.value)}}
          error={error.length > 0}
          helperText={error.length > 0 && error}
          InputProps={{
            endAdornment: (
                loading ? <Skeleton variant='rectangular' width={210} height={30}/> :
              <div>
                <Button
                  id='basic-button'
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{textTransform: 'lowercase'}}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  @{domain }
                </Button>

                <Menu
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={open}
                    onClose={() => handleClose(domain)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {domains.map((item, index) => (
                    <MenuItem key={index} onClick={() => handleClose(item)}>
                      {item}
                    </MenuItem>
                  ))}
                  {/* <MenuItem onClick={handleClose}>My account</MenuItem>
                  <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                </Menu>
              </div>
            ),
          }}
        />
        <Button sx={{ml: 4}} disabled={pending} variant='outlined' endIcon={<SendIcon/>} onClick={handleSubmit}>
            {
                pending ? <CircularProgress/> :
            "Invite"
            }
            </Button>
      </Grid>
    </div>
  )
}

export default PeersInvite
